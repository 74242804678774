import React from "react"
import { mdiEmail, mdiSignalVariant, mdiFileDocument } from '@mdi/js';
import LinkWithIcon from "./link-with-icon";
import { FOOTER_TEXT, SIGNUP_FORM_URL, LEGAL_NOTICE_URL } from "../constants";


const currentYear = new Date().getFullYear();

const feedLink = "/rss.xml";

const Footer = () => (
    <footer className="footer column pt-6">
        <div className="columns is-mobile mb-0">
            <div className="column is-narrow">
                <LinkWithIcon name="Feed" icon={mdiSignalVariant} link={feedLink} />
            </div>
            <div className="column is-narrow">
                <LinkWithIcon name="Signup for Updates" icon={mdiEmail} link={SIGNUP_FORM_URL} />
            </div>
        </div>
        <div className="is-size-5">
            &copy; {currentYear} {FOOTER_TEXT}
        </div>
        <div className="column is-narrow">
                <LinkWithIcon name="Legal Notice" icon={mdiFileDocument} link={LEGAL_NOTICE_URL} />
            </div>
    </footer>
)

export default Footer
