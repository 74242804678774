import React from "react"
import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js';
import { connectSearchBox } from "react-instantsearch-dom"

const SearchBox = connectSearchBox(
    ({ refine, currentRefinement, className, onFocus }) => (
        <div className="navbar-item">
            <div id="navSearch" className="control has-icons-left">
                <form className={className}>
                    <input
                        className="input is-rounded has-background-light"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        onChange={e => refine(e.target.value)}
                        value={currentRefinement}
                        onFocus={onFocus}
                    />
                    <div className="icon is-left has-text-grey" >
                        <Icon path={mdiMagnify} size={1} />
                    </div>
                </form>
            </div>
        </div>
    )
)

export default SearchBox;