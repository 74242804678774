import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom"
const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits
  return hitCount > 0 ? (
    <div className="hit-count">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null
})
const PageHit = ({ hit }) => (
  <div className="hit">
    <Link to={`/${hit.objectID}`}>
      <div className="is-flex is-align-items-center">
        <div className="logo-container">
          <img className="image is-object-fit-contain is-30x30" src={hit.logo} />
        </div>
        <div>
          <h4>
            <Highlight attribute="name" hit={hit} tagName="mark" />
          </h4>
          <Snippet className="snippet" attribute="short_description" hit={hit} tagName="mark" />
        </div>
      </div>
    </Link>
  </div>
)
const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="hits" hitComponent={PageHit} />
  </Index>
)
const SearchResult = ({ indices, show }) => (
  <div className={[`search-result`, show && "show"].join(' ')}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy />
  </div>
)
export default SearchResult