import Icon from "@mdi/react"
import { Link } from "gatsby"
import React from "react"

const LinkWithIcon = (props) => {

    const linkWithIconClass = `block ${props.link ? '' : 'no-hover'}`

    return (
        <div className={linkWithIconClass}>
            <Link to={props.link}>
                <div className="link-with-icons">
                    <div className="is-size-5">
                        <Icon path={props.icon} size={1} />
                    </div>
                    <div className={`title ${props.size ? props.size : 'is-4'}`}>{props.name}</div>
                </div>
            </Link>
        </div>
    )
}

export default LinkWithIcon
