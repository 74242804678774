import React from "react"

const BurgerButtonComponent = (props) => {

    const burgerClass = `navbar-burger
      ${props.IsOpened ? 'is-active' : ''}`;

    return (
        <a role="button" onClick={props.Toggle} className={burgerClass}
            aria-label="menu" aria-expanded="false" data-target="nav">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    )
}

export default BurgerButtonComponent