import { mdiMagnify } from "@mdi/js";
import React, { useState } from "react"
import BurgerButtonComponent from "./burger-button";
import Footer from "../footer"
import HeaderLinkComponent from "./header-link";
import { Search } from "./search";
import Icon from '@mdi/react'
import Logo from '../../images/widelogo.png'

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const joinClasses = (...classes) => classes.filter(c => c).join(' ');

const SearchToggle = ({ active, onClick }) => (
  <div className={joinClasses("search-toggle is-hidden-desktop", active && 'active')} onClick={onClick}>
    <Icon path={mdiMagnify} size={1} />
  </div>
);

const Header = () => {

  const [sideMenuIsOpened, setSideMenu] = useState(false);
  const [searchOpened, setSearchOpened] = useState(false);

  const navbarMenuClass = joinClasses(`navbar-menu`, sideMenuIsOpened && 'is-active');
  const searchToggleClass = joinClasses('mobile-search is-hidden-desktop', !searchOpened && 'is-hidden')

  const toggle = (setFn) => () => {
    setFn(value => !value);
  }

  const containerClass = `container ${sideMenuIsOpened ? 'is-opened' : ''}`

  return (
    <nav className="navbar is-gapless" role="navigation" aria-label="main navigation">
      <div className={containerClass}>
        <div className="navbar-brand">
          <a href="/" className="brand navbar-item">
            <img src={Logo}></img>
          </a>

          <SearchToggle onClick={toggle(setSearchOpened)} active={searchOpened} />
          <BurgerButtonComponent Toggle={toggle(setSideMenu)} IsOpened={sideMenuIsOpened} />
        </div>

        <div id="nav" className={navbarMenuClass}>
          <div className="navbar-end">
            <HeaderLinkComponent Link="/projects" Name="Directory" />
            <HeaderLinkComponent Link="/news" Name="News" />
            <HeaderLinkComponent Link="/grants" Name="w3f Grants" />
            <HeaderLinkComponent Link="/about" Name="About" />
            <Search className="is-hidden-touch" indices={searchIndices} />
            <div className="navbar-item footer">
              {sideMenuIsOpened && <Footer />}
            </div>
          </div>


        </div>
      </div>
      <div className={searchToggleClass}>
        <Search indices={searchIndices} />
      </div>
    </nav>
  )
}

export default Header
