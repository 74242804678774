import { Link } from "gatsby"
import React from "react"

const HeaderLinkComponent = (props) => {
    return (
        <Link className="navbar-item" to={props.Link}>
            {props.Name}
        </Link>
    )
}

export default HeaderLinkComponent